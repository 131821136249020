import React, { useState, useEffect } from 'react';
import {
  Grid, Typography, makeStyles, IconButton,
} from '@material-ui/core';
import {ArrowBackIos,ArrowForwardIos } from '@material-ui/icons';
import HeroBackground from './HeroBackground';
import StyledButton from '../atoms/StyledButton';
import Spacer from '../atoms/Spacer';
import CampanhaImageDesktop from '../../../static/images/061DesktopBannerSiteConubeMedicos.webp';
import CampanhaImageDesktopMei from '../../../static/images/BannersHomeSiteConubeMEI.png';
import CampanhaImageDesktopBlog from '../../../static/images/BannersHomeSiteConubeBLOG.png';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    marginTop: '30px',
    '& .span': {
      color: `${theme.palette.info.main} !important`,
    },
    '& .title strong': {
      color: theme.palette.info.highlight,
    },
  },
  description: {
    maxWidth: '390px',
  },
  button: {
    textDecoration: 'none',
    maxWidth: '65%',
    '@media screen and (max-width: 959px)': {
      maxWidth: '85%',
    },
  },
  carouselContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingTop: '50px',
    paddingRight: '30px',

    [theme.breakpoints.down('sm')]: {
      position: 'static', // <- impede sobreposição no mobile
      justifyContent: 'center',
      paddingTop: '20px',
      paddingRight: 0,
    },

    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
  imageWrapper: {
    position: 'relative',
    width: '100%',
    maxWidth: '600px',
  },
  photoImg: {
    width: '100%',
    borderRadius: '8px',
  },
  arrowButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
    padding: 3,
    width: 22,
    height: 22,

    [theme.breakpoints.down('xs')]: {
      width: 24,
      height: 24,
      padding: 2,
    },
  },
  leftArrow: {
    left: 5,
    marginLeft: 7
  },
  rightArrow: {
    right: 5,
  },
  dotsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1.5),
    width: '100%',
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    margin: '0 5px',
    backgroundColor: '#ccc',
    transition: 'background-color 0.3s',
  },
  activeDot: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const HomeHero = () => {
  const classes = useStyles();

  const banners = [
    {
      image: CampanhaImageDesktop,
      link: 'https://seja.conube.com.br/contabilidade-medicos-pj',
    },
    {
      image: CampanhaImageDesktopMei,
      link: 'https://api.whatsapp.com/send/?phone=5511995362311&amp;text&type=phone_number&app_absent=0',
    },
    {
      image: CampanhaImageDesktopBlog,
      link: 'https://conube.com.br/blog/',
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + banners.length) % banners.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 7000);
    return () => clearInterval(interval);
  }, []);

  return (
    <HeroBackground page="home">
      <Grid container spacing={2} className={classes.root}>
        <Grid item md={6} xs={12} className="text">
          <Typography className="span">Nascemos com um propósito</Typography>
          <Typography variant="h3" component="h1" className="title">
            Ser uma <strong>contabilidade</strong><br />
            <strong> digital feita sob medida</strong> para<br />
            o seu negócio
          </Typography>
          <Typography variant="body1" component="h2" className={classes.description}>
            Abra a sua empresa, emita notas fiscais em 1 minuto e
            economize até R$ 5 mil por ano.
          </Typography>
          <Spacer size={20} />
          <a href="/quanto-custa/" className={classes.button}>
            <StyledButton
              text="Saiba quanto custa"
              variant="contained"
              color="secondary"
              width="100%"
            />
          </a>
        </Grid>

        <Grid item md={6} xs={12} className={classes.carouselContainer}>
          <div className={classes.imageWrapper}>
            <IconButton className={`${classes.arrowButton} ${classes.leftArrow}`} onClick={prevSlide}>
              <ArrowBackIos/>
            </IconButton>

            <a href={banners[currentIndex].link} target="_blank" rel="noreferrer">
              <img
                className={classes.photoImg}
                alt={`banner-${currentIndex}`}
                src={banners[currentIndex].image}
              />
            </a>

            <IconButton className={`${classes.arrowButton} ${classes.rightArrow}`} onClick={nextSlide}>
              <ArrowForwardIos/>
            </IconButton>

            <div className={classes.dotsContainer}>
              {banners.map((_, index) => (
                <div
                  key={index}
                  className={`${classes.dot} ${index === currentIndex ? classes.activeDot : ''}`}
                />
              ))}
            </div>
          </div>
        </Grid>
      </Grid>
    </HeroBackground>
  );
};

export default HomeHero;
